export const colors = {
  primary: '#EC1C24',
  secondary: '#F5C373',
  black: '#222',
  orange: '#E56235',
};

export const fonts = {
  primary: 'jockey one, display',
  secondary: 'lato, sans-serif',
};
