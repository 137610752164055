import React from 'react';

interface Props {
  className?: string;
  scale?: number;
  color?: string;
  onClick?: () => void;
}

export const Hamburger = ({
  className,
  scale = 1,
  color = '#fff',
  onClick,
}: Props) => {
  return (
    <svg
      width={18 * scale}
      height={14 * scale}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0 10H18V8H0V10ZM0 14H18V12H0V14ZM0 6H18V4H0V6ZM0 0V2H18V0H0Z"
        fill={color}
      />
    </svg>
  );
};
