import React from 'react';

import { AnimateFramer } from './index';
import { motion } from 'framer-motion';

interface Props {
  className?: string;
  scale?: number;
  color?: string;
  animate?: AnimateFramer;
}

export const Arrow = ({
  className,
  scale = 1,
  color = '#fff',
  animate,
}: Props) => {
  return (
    <motion.svg
      width={12 * scale}
      height={11 * scale}
      viewBox="0 0 12 11"
      animate={animate}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.00002 0.331055L5.06002 1.27105L8.78002 4.99772H0.666687V6.33105H8.78002L5.06002 10.0577L6.00002 10.9977L11.3334 5.66439L6.00002 0.331055Z"
        fill={color}
      />
    </motion.svg>
  );
};
