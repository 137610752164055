import React from 'react';
import styled from 'styled-components';

import { ContactHeaderGraphic } from '@svg/graphic/contact-header-graphic';
import { ServicesHeaderGraphic } from '@svg/graphic/services-header-graphic';
import { CatalogsHeaderGraphic } from '@svg/graphic/catalogs-header-graphic';
import { AboutHeaderGraphic } from '@svg/graphic/about-header-graphic';
import { GalleryHeaderGraphic } from '@svg/graphic/gallery-header-graphic';
import { EmbroideryHeaderGraphic } from '@svg/graphic/embroidery-header-graphic';
import { VinylHeaderGraphic } from '@svg/graphic/vinyl-header-graphic';

interface Props {
  page: string;
}

export const Hero = ({ page }: Props) => {
  switch (page) {
    case 'about':
      return (
        <>
          <PageTitle>about us</PageTitle>
          <AboutHero />
        </>
      );
    case 'screen-printing':
      return (
        <>
          <PageTitle screenPrinting>screen printing</PageTitle>
          <ScreenPrintingHero />
        </>
      );
    case 'embroidery':
      return (
        <>
          <PageTitle embroidery>embroidery</PageTitle>
          <EmbroideryHero />
        </>
      );
    case 'vinyl':
      return (
        <>
          <PageTitle heatApplied>vinyl graphics</PageTitle>
          <VinylHero />
        </>
      );
    case 'catalogs':
      return (
        <>
          <PageTitle>catalogs</PageTitle>
          <CatalogsHero />
        </>
      );
    case 'contact':
      return (
        <>
          <PageTitle>contact</PageTitle>
          <ContactHero />
        </>
      );
    case 'gallery':
      return (
        <>
          <PageTitle>gallery</PageTitle>
          <GalleryHero />
        </>
      );
    case 'landing':
      return <React.Fragment />;
    default:
      return <PageTitle>Not Found</PageTitle>;
  }
};

interface PageTitleProps {
  heatApplied?: boolean;
  embroidery?: boolean;
  screenPrinting?: boolean;
}

const PageTitle = styled.h1<PageTitleProps>`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  text-transform: uppercase;
  font-family: 'jockey one';
  color: #ec1c24;
  transform: rotate(-8deg);
  transform-origin: 0 0;
  padding-top: 6.5vw;
  padding-left: 50px;
  z-index: 3;
  font-size: 64px;
  margin-left: ${({ embroidery }) => embroidery && '-25px'};
  margin-top: ${({ heatApplied, screenPrinting }) =>
    (heatApplied && '-40px') || (screenPrinting && '-35px')};

  @media (max-width: 675px) {
    margin-top: ${({ screenPrinting, heatApplied }) =>
      (heatApplied && '0px') || (screenPrinting && '0px')};
  }
`;

const ContactHero = styled(ContactHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;

const AboutHero = styled(AboutHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;

const ScreenPrintingHero = styled(ServicesHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;

const CatalogsHero = styled(CatalogsHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;

const GalleryHero = styled(GalleryHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;

const EmbroideryHero = styled(EmbroideryHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;

const VinylHero = styled(VinylHeaderGraphic)`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  z-index: 3;
`;
