import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

import { Icon } from '@svg/icon';
import { Logo } from '@svg/graphic/eon-logo';
import { Button } from '@components/Button';
import { colors, fonts } from '@utilities/theme';
import { AboutHeaderGraphic } from '@svg/graphic/about-header-graphic';
import { CatalogsHeaderGraphic } from '@svg/graphic/catalogs-header-graphic';
import { GalleryHeaderGraphic } from '@svg/graphic/gallery-header-graphic';
import { ServicesHeaderGraphic } from '@svg/graphic/services-header-graphic';
import { EmbroideryHeaderGraphic } from '@svg/graphic/embroidery-header-graphic';
import { VinylHeaderGraphic } from '@svg/graphic/vinyl-header-graphic';

export const DesktopNav = () => {
  const [isServicesHover, setIsServicesHover] = useState(false);
  const [isEmbroideryHover, setIsEmbroideryHover] = useState(false);
  const [isHeatAppliedHover, setIsHeatAppliedHover] = useState(false);
  const [isCatalogsHover, setIsCatalogsHover] = useState(false);
  const [isAboutHover, setIsAboutHover] = useState(false);
  const [isGalleryHover, setIsGalleryHover] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuAnimationState = {
    open: {
      height: 170,
      width: 325,
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
    closed: {
      height: 0,
      width: 0,
      opacity: 0,
      transition: {
        delay: 0.2,
      },
    },
  };

  const menuItemAnimationState = {
    open: {
      opacity: 1,
      scale: 1,
      x: 0,
    },
    closed: {
      opacity: 0,
      x: 60,
      scale: 0,
    },
  };

  const arrowIconAnimationState = {
    open: {
      rotate: 90,
      x: 8,
      y: -2,
    },
    closed: {
      rotate: 0,
      x: 0,
      y: 0,
    },
  };

  return (
    <nav>
      <NavItems>
        <NavItem>
          <Link to="/">
            <Logo scale={1.75} />
          </Link>
        </NavItem>
        <NavItem onClick={() => setIsMenuOpen(!isMenuOpen)} className="margin">
          <Services>
            <span>Services</span>
            <motion.span
              initial="closed"
              animate={isMenuOpen ? 'open' : 'closed'}
              variants={arrowIconAnimationState}
            >
              <ArrowIcon scale={1.5} name="arrow-small" />
            </motion.span>
            <AnimatePresence>
              {isMenuOpen && (
                <Menu
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={menuAnimationState}
                >
                  <MenuItemsContainer>
                    <MenuItems>
                      <MenuItemLink to="/screenprint">
                        <ScreenPrintingMenuItem
                          onHoverStart={() => setIsServicesHover(true)}
                          onHoverEnd={() => setIsServicesHover(false)}
                          variants={menuItemAnimationState}
                        >
                          <motion.span
                            animate={{
                              opacity: isServicesHover ? 1 : 0,
                              x: isServicesHover ? 0 : -30,
                            }}
                          >
                            <ServicesIcon
                              bgColor="#fff"
                              color={colors.black}
                              scale={0.15}
                            />
                          </motion.span>
                          <motion.p
                            animate={{
                              x: isServicesHover ? 0 : -50,
                            }}
                          >
                            Screen Printing
                          </motion.p>
                        </ScreenPrintingMenuItem>
                      </MenuItemLink>
                      <MenuItemLink to="/embroidery">
                        <EmbroideryMenuItem
                          onHoverStart={() => setIsEmbroideryHover(true)}
                          onHoverEnd={() => setIsEmbroideryHover(false)}
                          variants={menuItemAnimationState}
                        >
                          <motion.span
                            animate={{
                              opacity: isEmbroideryHover ? 1 : 0,
                              x: isEmbroideryHover ? 0 : -30,
                            }}
                            style={{ marginTop: -22 }}
                          >
                            <EmbroideryIcon
                              needleOnly
                              bgColor="#fff"
                              color={colors.black}
                              scale={0.25}
                            />
                          </motion.span>
                          <motion.p
                            animate={{
                              x: isEmbroideryHover ? 0 : -50,
                            }}
                            style={{ position: 'relative', zIndex: 1 }}
                          >
                            Embroidery
                          </motion.p>
                        </EmbroideryMenuItem>
                      </MenuItemLink>
                      <MenuItemLink to="/vinyl">
                        <HeatAppliedMenuItem
                          onHoverStart={() => setIsHeatAppliedHover(true)}
                          onHoverEnd={() => setIsHeatAppliedHover(false)}
                          variants={menuItemAnimationState}
                        >
                          <motion.span
                            animate={{
                              opacity: isHeatAppliedHover ? 1 : 0,
                              x: isHeatAppliedHover ? 0 : -30,
                            }}
                            style={{ marginTop: -22 }}
                          >
                            <VinylIcon
                              bgColor="#fff"
                              color={colors.black}
                              scale={0.25}
                            />
                          </motion.span>
                          <motion.p
                            animate={{
                              x: isHeatAppliedHover ? 10 : -50,
                            }}
                            style={{
                              position: 'relative',
                              zIndex: 1,
                            }}
                          >
                            Heat Applied Graphics
                          </motion.p>
                        </HeatAppliedMenuItem>
                      </MenuItemLink>
                    </MenuItems>
                  </MenuItemsContainer>
                </Menu>
              )}
            </AnimatePresence>
          </Services>
        </NavItem>
        <NavItem className="hover">
          <Link to="/catalogs">
            <Catalogs
              onHoverStart={() => setIsCatalogsHover(true)}
              onHoverEnd={() => setIsCatalogsHover(false)}
            >
              <motion.span
                animate={{
                  opacity: isCatalogsHover ? 1 : 0,
                }}
              >
                <CatalogsIcon
                  bgColor={colors.black}
                  color="#fff"
                  scale={0.15}
                />
              </motion.span>
              <motion.p animate={{ x: isCatalogsHover ? 5 : -15 }}>
                Catalogs
              </motion.p>
            </Catalogs>
          </Link>
        </NavItem>
        <NavItem className="hover">
          <Link to="/about">
            <About
              onHoverStart={() => setIsAboutHover(true)}
              onHoverEnd={() => setIsAboutHover(false)}
            >
              <motion.span
                animate={{
                  opacity: isAboutHover ? 1 : 0,
                }}
              >
                <AboutIcon bgColor={colors.black} color="#fff" scale={0.15} />
              </motion.span>
              <motion.p animate={{ x: isAboutHover ? 5 : -15 }}>
                About Us
              </motion.p>
            </About>
          </Link>
        </NavItem>
        <NavItem className="hover">
          <Link to="/gallery">
            <Gallery
              onHoverStart={() => setIsGalleryHover(true)}
              onHoverEnd={() => setIsGalleryHover(false)}
            >
              <motion.span
                animate={{
                  opacity: isGalleryHover ? 1 : 0,
                }}
              >
                <GalleryIcon bgColor={colors.black} color="#fff" scale={0.15} />
              </motion.span>
              <motion.p animate={{ x: isGalleryHover ? 5 : -15 }}>
                Gallery
              </motion.p>
            </Gallery>
          </Link>
        </NavItem>
        <NavItem />
        <NavItem>
          <Link to="/contact">
            <ButtonStyle
              icon={
                <PhoneIcon
                  name="old-phone"
                  scale={1.5}
                  color={colors.primary}
                  hoverColor="#fff"
                />
              }
            >
              Contact
            </ButtonStyle>
          </Link>
        </NavItem>
      </NavItems>
    </nav>
  );
};

const ButtonStyle = styled(Button)`
  .active-contact {
    background-color: #ec1c24;
    color: white;
  }
`;

const NavItems = styled.ul`
  margin: 0;
  padding: 0 50px;
  color: white;
  font-family: 'jockey one';
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, auto) 1fr auto;
  align-items: center;

  .margin {
    margin-left: 50px;
  }
`;

const NavItem = styled(motion.li)`
  margin: 0;
  padding: 15px;
  position: relative;

  a:link,
  a:visited {
    color: white;
    text-decoration: none;
  }
`;

const PhoneIcon = styled(Icon)`
  transform: rotate(-8deg);
  transform-origin: center center;
  margin-left: 1em;
`;

const Services = styled(motion.span)`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  align-content: center;
  position: relative;
  cursor: pointer;
`;

const About = styled(Services)``;
const Catalogs = styled(Services)``;
const Gallery = styled(Services)``;

const ServicesIcon = styled(ServicesHeaderGraphic)`
  transform: translate3d(0, 5px, 0);
`;

const AboutIcon = styled(AboutHeaderGraphic)`
  transform: translate3d(0, 5px, 0);
`;

const CatalogsIcon = styled(CatalogsHeaderGraphic)`
  transform: translate3d(0, 5px, 0);
`;

const GalleryIcon = styled(GalleryHeaderGraphic)`
  transform: translate3d(0, 5px, 0);
`;

const VinylIcon = styled(VinylHeaderGraphic)`
  transform: translate3d(-10px, 10px, 0);
  position: relative;
  z-index: 0;
`;

const EmbroideryIcon = styled(EmbroideryHeaderGraphic)`
  transform: translate3d(-10px, 10px, 0);
  position: relative;
  z-index: 0;
`;

const ArrowIcon = styled(Icon)`
  margin-left: 10px;
`;

const Menu = styled(motion.div)`
  position: absolute;
  margin-top: 40px;
  top: 0;
  left: 0;
  background: white;
  color: ${colors.black};
  border-radius: 2px;
  padding-top: 10px;
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.025),
    0px 13px 27px rgba(50, 50, 93, 0.25);
  z-index: 101;
`;

const MenuItemsContainer = styled.div`
  position: relative;
  z-index: 100;
`;

const MenuItems = styled.ul`
  position: absolute;
  left: 0;
  top: 0;
  list-style-type: none;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  z-index: 100;
`;

const MenuItemLink = styled(Link)`
  color: ${colors.black} !important;
  font-size: 24px;
  font-family: ${fonts.primary};
  text-decoration: none;
`;

const ScreenPrintingMenuItem = styled(motion.li)`
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  align-content: center;
`;

const EmbroideryMenuItem = styled(ScreenPrintingMenuItem)``;
const HeatAppliedMenuItem = styled(ScreenPrintingMenuItem)``;
