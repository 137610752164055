import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors, fonts } from './utilities/theme';

interface Props {
  primary?: boolean;
  icon?: JSX.Element;
  className?: string;
  full?: boolean;
  red?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

interface BtnProps {
  primary?: boolean;
  full?: boolean;
  red?: boolean;
}

export const Button: React.FC<Props> = ({
  children,
  icon,
  type,
  red,
  primary,
  full,
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Btn
      className={className}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{
        color: '#ffffff',
        backgroundColor: '#ec1c24',
      }}
      primary={primary}
      full={full}
      red={red}
      type={type}
    >
      {children}
      {icon && React.cloneElement(icon, { isHovered: isHovered })}
    </Btn>
  );
};

const Btn = styled(motion.button)<BtnProps>`
  font-family: ${fonts.primary};
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 2px;
  padding: 5px 20px;
  background: ${({ primary, red }) =>
    primary ? '#222' : red ? colors.primary : 'white'};
  color: ${({ primary, red }) => (primary ? 'white' : red ? 'white' : '#222')};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${({ full }) => (full ? '100%' : 'initial')};
`;
