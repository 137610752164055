import React from 'react';
import styled from 'styled-components';
import { Link, withPrefix, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Logo } from '../svg/graphic/eon-logo';
import { Icon } from '../svg/icon';
import ChamberLogo from '../svg/graphic/chamber-logo.svg';
import { colors } from '../utilities/theme';
import { GatsbyImage } from '@utilities/gatsby-image';

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      nfib: file(relativePath: { eq: "nfib-logo-white.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <YellowDiv />
      <Container>
        <EONLogo scale={3} />
        <Space />
        <Contact>
          <h3 className="heading">contact</h3>
          <div className="hours">
            <Icon
              name="clock"
              scale={0.85}
              color={colors.black}
              className="hours-icon"
            />
            <p className="days">Monday - Friday</p>
            <p className="times">9:30am - 5:30pm</p>
          </div>
          <div className="location">
            <Icon name="location" scale={0.85} className="location-icon" />
            <p className="street">836 North Institute Street</p>
            <p className="city">Colorado Springs, CO</p>
            <p className="zip">80903</p>
          </div>
          <div className="phone">
            <Icon
              name="phone"
              color={colors.black}
              scale={0.8}
              className="phone-icon"
            />
            <p className="phone-number">719-633-7986</p>
          </div>
          <div className="email">
            <Icon
              className="email-icon"
              color={colors.black}
              scale={0.8}
              name="email"
            />
            <p className="email-text">eonstudios@earthlink.net</p>
          </div>
        </Contact>
        <Accreditation>
          <h3 className="heading">accreditation</h3>
          <NFIBLogo fixed={data.nfib.childImageSharp.fixed} alt="NFIB Logo" />
          <ChamberLogo />
        </Accreditation>
        <SiteLinks>
          <h3 className="heading">site links</h3>
          <Link to="/screenprint">
            Screen Print <Icon name="arrow-small" className="icon" />
          </Link>
          <Link to="/embroidery">
            Embroidery <Icon name="arrow-small" className="icon" />
          </Link>
          <Link to="/vinyl">
            Vinyl <Icon name="arrow-small" className="icon" />
          </Link>
          <Link to="/catalogs">
            Catalogs <Icon name="arrow-small" className="icon" />
          </Link>
          <Link to="/about">
            About <Icon name="arrow-small" className="icon" />
          </Link>
          <Link to="/gallery">
            Gallery <Icon name="arrow-small" className="icon" />
          </Link>
        </SiteLinks>
      </Container>
    </>
  );
};

const YellowDiv = styled.div`
  margin-top: 3vw;
  height: 16px;
  width: 100%;
  background: #f5c373;
  position: relative;

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill='%23f5c373' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,100 100,100 100,10' /%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 3vw;
    position: absolute;
    width: 100%;
    top: -3vw;
    z-index: 2;
  }
`;

const Container = styled.footer`
  background: #222;
  padding: 50px;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) repeat(3, auto);
  grid-auto-flow: column;
  position: relative;

  @media (max-width: 1065px) {
    justify-items: center;
    row-gap: 50px;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }

  @media (max-width: 674px) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    justify-content: space-around;
  }

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill='%23222' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,100 100,100 100,6.5' /%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 1vw;
    position: absolute;
    width: 100%;
    top: -1vw;
    z-index: 4;
  }

  .heading {
    font-family: 'jockey one';
    font-size: 18px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    margin: 0;
  }
`;

const Space = styled.div`
  @media (max-width: 1065px) {
    display: none;
  }
`;

const Contact = styled.div`
  display: grid;
  grid-template-rows: repeat(6, auto);
  row-gap: 1em;
  font-size: 16px;
  font-weight: 700;
  font-family: lato;
  color: white;
  line-height: 1.25;
  min-width: 180px;
  margin-left: 100px;

  @media (max-width: 1065px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-left: 0;
  }

  @media (max-width: 674px) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .heading {
    background-image: url(${withPrefix('contact-header-bg.png')});
    background-position: 5px;
    background-repeat: no-repeat;
    padding-left: 20px;
  }

  .hours {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 1em;
    align-items: center;
    justify-content: start;
    grid-template-areas:
      'icon days'
      'icon times';

    .hours-icon {
      grid-area: icon;
      margin: 0;
    }

    .days {
      grid-area: days;
      margin: 0;
    }

    .times {
      grid-area: times;
      margin: 0;
    }
  }

  .location {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    column-gap: 1em;
    align-items: center;
    justify-content: start;
    grid-template-areas:
      'icon street'
      'icon city'
      'icon zip';

    .location-icon {
      grid-area: icon;
      margin: 0;
    }
    .street {
      grid-area: street;
      margin: 0;
    }
    .city {
      grid-area: city;
      margin: 0;
    }
    .zip {
      grid-area: zip;
      margin: 0;
    }
  }

  .phone,
  .email {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-flow: column;
    column-gap: 1em;
    align-items: center;
    justify-content: start;
    grid-template-areas: 'icon number';

    .phone-icon,
    .email-icon {
      grid-area: icon;
    }
    .phone-number,
    .email-text {
      grid-area: number;
      margin: 0;
    }
  }
`;

const Accreditation = styled.div`
  display: grid;
  row-gap: 1em;
  grid-template-columns: 100px auto;
  grid-template-rows: auto auto;
  justify-items: center;
  column-gap: 50px;
  margin-left: 100px;

  @media (max-width: 1065px) {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    margin-left: 0;
  }

  @media (max-width: 674px) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .heading {
    grid-column: 1 / -1;
    background-image: url(${withPrefix('accreditation-header-bg.png')});
    background-repeat: no-repeat;
    padding: 0 20px;
    background-position: 5px top;
  }
`;

const NFIBLogo = styled(Img)<GatsbyImage>`
  align-self: center;

  @media (max-width: 1065px) {
    align-self: end;
  }

  @media (max-width: 674px) {
    align-self: end;
  }
`;

const SiteLinks = styled.div`
  display: grid;
  grid-template-rows: repeat(5, auto);
  min-width: 70px;
  margin-left: 100px;

  @media (max-width: 1065px) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    margin-left: 0;
  }

  @media (max-width: 674px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .heading {
    background-image: url(${withPrefix('sitelinks-header-bg.png')});
    background-repeat: no-repeat;
    padding: 0 10px;
    background-position: 0px top;
  }

  a:link,
  a:visited {
    font-family: lato;
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #ec1c24;
  }

  .icon {
    margin-left: 5px;
  }
`;

const EONLogo = styled(Logo)`
  grid-row: 1 / -1;
  align-self: center;

  @media (max-width: 674px) {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
  }
`;
