import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from './utilities/theme';

interface Props {
  behindText: string;
  fontSizeFront?: string;
  fontSizeBack?: string;
  className?: string;
  light?: boolean;
}

interface ContainerProps {
  light?: boolean;
}

interface FrontProps {
  fontSizeFront?: string;
}

interface BehindProps {
  light?: boolean;
  fontSizeBack?: string;
}

export const Heading: React.FC<Props> = ({
  children,
  behindText,
  light = false,
  fontSizeFront,
  fontSizeBack,
  className,
}) => {
  return (
    <Container light={light} className={className}>
      <Behind fontSizeBack={fontSizeBack} light={light} className="behind">
        {behindText}
      </Behind>
      <Front fontSizeFront={fontSizeFront} className="front">
        {children}
      </Front>
    </Container>
  );
};

const Container = styled.h2<ContainerProps>`
  font-family: ${fonts.primary};
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ light }) => (light ? 'white' : colors.black)};
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  text-align: center;
`;

const Front = styled.span<FrontProps>`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: ${({ fontSizeFront }) => (fontSizeFront ? fontSizeFront : '50px')};
`;

const Behind = styled.span<BehindProps>`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: ${({ fontSizeBack }) => (fontSizeBack ? fontSizeBack : '72px')};
  color: ${({ light }) =>
    light ? 'rgba(255, 255, 255, 0.2)' : 'rgba(34, 34, 34, 0.1)'};
  margin-top: -36px;
`;
