import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  className?: string;
  scale?: number;
  color?: string;
  hoverColor?: string;
  isHovered?: boolean;
}

export const OldPhone = ({
  className,
  scale = 1,
  color = '#ec1c24',
  hoverColor,
  isHovered,
}: Props) => {
  return (
    <motion.svg
      width={16 * scale}
      height={13 * scale}
      className={className}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      animate={{
        fill: isHovered && hoverColor ? hoverColor : color,
        rotate: isHovered ? '8deg' : '-8deg',
      }}
    >
      <path d="M13.3744 8.23125C13.3024 7.731 12.9161 7.341 12.4189 7.22475C10.4861 6.77175 10.0016 5.65875 9.91989 4.42425C9.57714 4.3605 8.96739 4.2915 7.93239 4.2915C6.89739 4.2915 6.28764 4.3605 5.94489 4.42425C5.86314 5.65875 5.37864 6.77175 3.44589 7.22475C2.94864 7.34175 2.56239 7.731 2.49039 8.23125L2.11764 10.8128C1.98639 11.7218 2.65389 12.5415 3.58239 12.5415H12.2824C13.2101 12.5415 13.8784 11.7218 13.7471 10.8128L13.3744 8.23125ZM7.93239 10.6605C6.88614 10.6605 6.03789 9.8205 6.03789 8.7855C6.03789 7.7505 6.88614 6.9105 7.93239 6.9105C8.97864 6.9105 9.82689 7.7505 9.82689 8.7855C9.82689 9.8205 8.97789 10.6605 7.93239 10.6605ZM15.3949 3.5415C15.3769 2.4165 12.5134 0.542254 7.93239 0.541504C3.35064 0.542254 0.487145 2.4165 0.469895 3.5415C0.452645 4.6665 0.485645 6.1305 2.37114 5.88675C4.57689 5.601 4.44114 4.83075 4.44114 3.72975C4.44114 2.96175 6.23514 2.7765 7.93239 2.7765C9.62964 2.7765 11.4229 2.96175 11.4236 3.72975C11.4236 4.83075 11.2879 5.601 13.4936 5.88675C15.3784 6.1305 15.4121 4.6665 15.3949 3.5415Z" />
    </motion.svg>
  );
};
