import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Logo } from '../../../svg/graphic/eon-logo';
import { MobileMenuContext } from '../../../Provider';
import { fonts } from '../../../utilities/theme';
import { Icon } from '../../../svg/icon';

export const MobileNav = () => {
  const { toggleIsOpen } = useContext(MobileMenuContext);

  return (
    <nav>
      <NavItems>
        <NavItem>
          <Link to="/">
            <Logo scale={1.75} />
          </Link>
        </NavItem>
        <NavItem />
        <NavItem onClick={() => toggleIsOpen()}>
          <Icon name="hamburger" color="#fff" scale={2} />
        </NavItem>
      </NavItems>
    </nav>
  );
};

const NavItems = styled.ul`
  margin: 0;
  padding: 0 25px;
  color: white;
  font-family: ${fonts.primary};
  font-weight: 500;
  font-size: 24px;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  @media (max-width: 675px) {
    padding: 0 5px;
  }
`;

const NavItem = styled.li`
  margin: 0;
  padding: 15px;
`;
