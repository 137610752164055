import React from 'react';

interface Props {
  className?: string;
  scale?: number;
  color?: string;
}

export const ArrowSmall = ({ className, scale = 1, color = '#fff' }: Props) => {
  return (
    <svg
      width={7 * scale}
      height={10 * scale}
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.437988 8.88951L3.97551 5.34427L0.437988 1.79902L1.52705 0.709961L6.16136 5.34427L1.52705 9.97858L0.437988 8.88951Z"
        fill={color}
      />
    </svg>
  );
};
