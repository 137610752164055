import React from 'react';
import styled from 'styled-components';

import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { colors } from '../../utilities/theme';

export const Nav = () => {
  return (
    <>
      <Desktop>
        <DesktopNav />
      </Desktop>
      <Mobile>
        <MobileNav />
      </Mobile>
    </>
  );
};

const Desktop = styled.div`
  display: none;

  @media (min-width: 1084px) {
    display: block;
    background: ${colors.black};
  }
`;

const Mobile = styled.div`
  display: none;

  @media (max-width: 1083px) {
    display: block;
    background: ${colors.black};
  }
`;
