import React from 'react';

interface Props {
  className?: string;
  scale?: number;
  color?: string;
}

export const Quote = ({ className, scale = 1, color = '#91CDD4' }: Props) => {
  return (
    <svg
      width={29 * scale}
      height={23 * scale}
      viewBox="0 0 29 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.36395 22.0839H8.37036L12.3746 13.3402V0.224609H0.361816V13.3402H6.36823L2.36395 22.0839ZM18.381 22.0839H24.3875L28.3917 13.3402V0.224609H16.3789V13.3402H22.3853L18.381 22.0839Z"
        fill={color}
      />
    </svg>
  );
};
