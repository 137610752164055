import React from 'react';
import { motion } from 'framer-motion';
import { AnimateFramer } from './index';

interface Props {
  className?: string;
  scale?: number;
  color?: string;
  animate?: AnimateFramer;
}

export const Browse = ({
  className,
  scale = 1,
  color = '#fff',
  animate,
}: Props) => {
  return (
    <motion.svg
      animate={animate}
      width={26 * scale}
      height={26 * scale}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.1204 14.5011V23.2158H2.69108V5.78645H8.94074C9.00299 4.90254 9.21463 4.06842 9.53832 3.29655H2.69108C1.32163 3.29655 0.201172 4.41701 0.201172 5.78645V23.2158C0.201172 24.5852 1.32163 25.7057 2.69108 25.7057H20.1204C21.4899 25.7057 22.6103 24.5852 22.6103 23.2158V16.991L20.1204 14.5011ZM18.253 20.7259H4.55851L7.98213 16.3312L10.4222 19.2693L13.8459 14.8622L18.253 20.7259ZM21.7389 9.38437C22.2866 8.5129 22.6103 7.50449 22.6103 6.40893C22.6103 3.309 20.108 0.806641 17.008 0.806641C13.9081 0.806641 11.4058 3.309 11.4058 6.40893C11.4058 9.50886 13.9081 12.0112 16.9956 12.0112C18.0912 12.0112 19.112 11.6875 19.971 11.1398L23.8553 15.024L25.6231 13.2562L21.7389 9.38437ZM17.008 9.52131C15.29 9.52131 13.8957 8.12697 13.8957 6.40893C13.8957 4.69089 15.29 3.29655 17.008 3.29655C18.7261 3.29655 20.1204 4.69089 20.1204 6.40893C20.1204 8.12697 18.7261 9.52131 17.008 9.52131Z"
        fill={color}
      />
    </motion.svg>
  );
};
