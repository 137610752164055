import React from 'react';
import styled from 'styled-components';

import { Hero } from './Hero';
import { GrungeHeaderBG } from '../../svg/graphic/grunge-header-bg';
import { Nav } from '../Nav';

interface Props {
  page: string;
}

interface HeadingProps {
  page?: string;
}

export const Header = ({ page }: Props) => {
  return (
    <>
      <HTMLHeading>
        <Nav />
      </HTMLHeading>
      <Heading page={page}>
        <GrungeBG page={page} />
        <Hero page={page} />
      </Heading>
    </>
  );
};

const HTMLHeading = styled.header`
  background: #222;
  position: relative;
  z-index: 4;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill='%23222' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,0 100,0 0,6.5' /%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: 30vw;
    position: absolute;
    width: 100%;
    bottom: -30vw;
    z-index: 5;
  }
`;

const Heading = styled.div<HeadingProps>`
  height: ${({ page }) => (page === 'landing' ? '16px' : '70px')};
  background: #f5c373;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 360px auto;
  margin-bottom: 9vw;
  z-index: 3;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' fill='%23f5c373' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,0 100,0 0,84' /%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    height: ${({ page }) => (page === 'landing' ? '6vw' : '10vw')};
    bottom: ${({ page }) => (page === 'landing' ? '-6vw' : '-10vw')};
    position: absolute;
    width: 100%;
    z-index: 2;
  }
`;

const GrungeBG = styled(GrungeHeaderBG)<HeadingProps>`
  display: ${({ page }) => (page === 'landing' ? 'none' : 'initial')};
`;
