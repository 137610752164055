import React from 'react';
import { TargetAndTransition, AnimationControls } from 'framer-motion';

import { ArrowSmall } from './arrow-small';
import { OldPhone } from './old-phone';
import { Hamburger } from './hamburger';
import { Exit } from './exit';
import { History } from './history';
import { Location } from './location';
import { HighQuality } from './high-quality';
import { Hoody } from './hoody';
import { LongSleeve } from './longsleeve';
import { Painting } from './painting';
import { Person } from './person';
import { Printer } from './printer';
import { Sweatshirt } from './sweatshirt';
import { TShirt } from './tshirt';
import { Clock } from './clock';
import { Email } from './email';
import { Phone } from './phone';
import { Quote } from './quote';
import { PersonalTouch } from './personal-touch';
import { Computer } from './computer';
import { Create } from './create';
import { House } from './house';
import { LowPrice } from './low-price';
import { Nametag } from './nametag';
import { Football } from './football';
import { Variety } from './variety';
import { Browse } from './browse';
import { Arrow } from './arrow';

export type IconNames =
  | 'arrow'
  | 'arrow-small'
  | 'clock'
  | 'location'
  | 'old-phone'
  | 'phone'
  | 'hamburger'
  | 'exit'
  | 'history'
  | 'high-quality'
  | 'hoody'
  | 'long-sleeve'
  | 'painting'
  | 'person'
  | 'printer'
  | 'sweatshirt'
  | 'tshirt'
  | 'email'
  | 'clock'
  | 'personal-touch'
  | 'computer'
  | 'create'
  | 'house'
  | 'low-price'
  | 'nametag'
  | 'sport'
  | 'variety'
  | 'browse'
  | 'quote';

export type AnimateFramer =
  | string
  | boolean
  | AnimationControls
  | TargetAndTransition
  | string[]
  | undefined;

interface Props {
  name?: IconNames;
  className?: string;
  scale?: number;
  color?: string;
  bgColor?: string;
  hoverColor?: string;
  isHovered?: boolean;
  onClick?: () => void;
  animate?: AnimateFramer;
}

export const Icon = ({
  name,
  className,
  scale,
  color,
  bgColor,
  hoverColor,
  isHovered,
  onClick,
  animate,
}: Props) => {
  switch (name) {
    case 'arrow':
      return (
        <Arrow
          animate={animate}
          scale={scale}
          className={className}
          color={color}
        />
      );
    case 'arrow-small':
      return <ArrowSmall scale={scale} className={className} color={color} />;
    case 'browse':
      return (
        <Browse
          animate={animate}
          scale={scale}
          className={className}
          color={color}
        />
      );
    case 'clock':
      return (
        <Clock
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'old-phone':
      return (
        <OldPhone
          scale={scale}
          className={className}
          color={color}
          hoverColor={hoverColor}
          isHovered={isHovered}
        />
      );
    case 'hamburger':
      return (
        <Hamburger
          scale={scale}
          className={className}
          color={color}
          onClick={onClick}
        />
      );
    case 'exit':
      return (
        <Exit
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
          onClick={onClick}
        />
      );
    case 'history':
      return (
        <History
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'location':
      return (
        <Location
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'high-quality':
      return (
        <HighQuality
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'hoody':
      return (
        <Hoody
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'long-sleeve':
      return (
        <LongSleeve
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'painting':
      return (
        <Painting
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'person':
      return (
        <Person
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'printer':
      return (
        <Printer
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'sweatshirt':
      return (
        <Sweatshirt
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'tshirt':
      return (
        <TShirt
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'email':
      return (
        <Email
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'clock':
      return (
        <Clock
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'phone':
      return (
        <Phone
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'personal-touch':
      return (
        <PersonalTouch
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'computer':
      return (
        <Computer
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'create':
      return (
        <Create
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'house':
      return (
        <House
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'low-price':
      return (
        <LowPrice
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'nametag':
      return (
        <Nametag
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'sport':
      return (
        <Football
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'variety':
      return (
        <Variety
          scale={scale}
          className={className}
          color={color}
          bgColor={bgColor}
        />
      );
    case 'quote':
      return <Quote scale={scale} className={className} color={color} />;
    default:
      return <div />;
  }
};

export default Icon;
