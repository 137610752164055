import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

import { MobileMenuContext } from '@components/Provider';
import { colors, fonts } from '@utilities/theme';
import { Icon } from '@svg/icon';
import { Logo } from '@svg/graphic/eon-logo';
import { ContactHeaderGraphic } from '@svg/graphic/contact-header-graphic';
import { ServicesHeaderGraphic } from '@svg/graphic/services-header-graphic';
import { CatalogsHeaderGraphic } from '@svg/graphic/catalogs-header-graphic';
import { AboutHeaderGraphic } from '@svg/graphic/about-header-graphic';
import { GalleryHeaderGraphic } from '@svg/graphic/gallery-header-graphic';
import { EmbroideryHeaderGraphic } from '@svg/graphic/embroidery-header-graphic';
import { VinylHeaderGraphic } from '@svg/graphic/vinyl-header-graphic';

export const Menu = () => {
  const { isOpen, toggleIsOpen } = useContext(MobileMenuContext);

  const sliderSpringState = {
    type: 'spring',
    damping: 20,
    stiffness: 100,
  };

  const menuSpringState = {
    type: 'spring',
    damping: 20,
    stiffness: 100,
    mass: 0.5,
    staggerChildren: 0.1,
    delayChildren: 0.2,
  };

  //   const menuItemSpringState = {
  //     type: 'spring',
  //     dampening: 10,
  //     // mass: 0.1,
  //     stiffness: 10,
  //   };

  const sliderAnimationState = {
    visible: {
      x: '-100vw',
      //   transition: sliderSpringState,
    },
    hidden: {
      x: '100vw',
      //   transition: sliderSpringState,
    },
  };

  const menuAnimationState = {
    visible: {
      x: 0,
      opacity: 1,
      //   transition: menuSpringState,
    },
    hidden: {
      x: '100vw',
      opacity: 0,
      //   transition: menuSpringState,
    },
  };

  const menuItemAnimationState = {
    visible: {
      opacity: 1,
      x: 0,
      //   transition: menuItemSpringState,
    },
    hidden: {
      opacity: 0,
      x: '60px',
      //   transition: menuItemSpringState,
    },
  };

  const goTo = (url: string) => {
    toggleIsOpen();
    navigate(url);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <Slider
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={sliderAnimationState}
            transition={sliderSpringState}
          />
          <Background
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={menuAnimationState}
            transition={menuSpringState}
          >
            <LogoColumn>
              <EONLogoTablet onClick={() => goTo('/')} to="/">
                <Logo regular scale={7} />
              </EONLogoTablet>
            </LogoColumn>
            <MenuColumn>
              <MobileMainNav>
                <EONLogoMobile onClick={() => goTo('/')} to="/">
                  <Logo bgRed scale={2} />
                </EONLogoMobile>
                <ExitIcon
                  onClick={() => toggleIsOpen()}
                  name="exit"
                  color={colors.primary}
                  bgColor="white"
                  scale={0.75}
                />
              </MobileMainNav>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/screenprint')}>
                  <ServicesHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  Screen Print
                </LinkGrid>
              </LinkGridContainer>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/embroidery')}>
                  <EmbroideryHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  Embroidery
                </LinkGrid>
              </LinkGridContainer>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/vinyl')}>
                  <VinylHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  Vinyl
                </LinkGrid>
              </LinkGridContainer>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/catalogs')}>
                  <CatalogsHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  Catalogs
                </LinkGrid>
              </LinkGridContainer>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/about')}>
                  <AboutHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  About Us
                </LinkGrid>
              </LinkGridContainer>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/gallery')}>
                  <GalleryHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  Gallery
                </LinkGrid>
              </LinkGridContainer>
              <LinkGridContainer
                variants={menuItemAnimationState}
                // transition={menuItemSpringState}
              >
                <LinkGrid onClick={() => goTo('/contact')}>
                  <ContactHeaderGraphic
                    bgColor="white"
                    color={colors.primary}
                    scale={0.3}
                    className="icon"
                  />
                  Contact
                </LinkGrid>
              </LinkGridContainer>
            </MenuColumn>
          </Background>
        </>
      )}
    </AnimatePresence>
  );
};

const Slider = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: ${colors.black};
`;

const Background = styled(motion.ul)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 805px) {
    grid-template-columns: 1fr;
  }
`;

const LogoColumn = styled.div`
  @media (max-width: 805px) {
    display: none;
  }

  display: block;
  background: white;
  display: grid;
  grid-template-rows: 1fr;
  padding: 25px;
  align-items: center;
`;

const MenuColumn = styled.div`
  background: ${colors.primary};
  list-style-type: none;
  display: grid;
  grid-template-rows: repeat(5, auto);
  padding: 30px;
  align-content: start;
  row-gap: 0.5em;
  align-items: center;
  font-family: ${fonts.primary};
  font-size: 48px;
  text-transform: uppercase;

  @media (max-width: 805px) {
    grid-template-rows: repeat(6, auto);
    justify-content: stretch;
  }

  @media (max-height: 725px) {
    font-size: 32px;
    grid-template-columns: 1fr;
    row-gap: 0;
  }
`;

const MobileMainNav = styled.li`
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: 805px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const EONLogoMobile = styled(Link)`
  @media (min-width: 805px) {
    display: none;
  }
  cursor: pointer;
`;

const EONLogoTablet = styled(Link)`
  @media (max-width: 805px) {
    display: none;
  }

  justify-self: center;
  cursor: pointer;
`;

const LinkGridContainer = styled(motion.li)`
  justify-self: center;

  @media (max-width: 805px) {
    justify-self: start;
  }
`;

const LinkGrid = styled.button`
  display: flex;
  grid-template-columns: auto auto;
  align-items: center;
  align-content: center;
  justify-content: start;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  text-transform: uppercase;

  .icon {
    @media (max-height: 725px) {
      width: 30%;
      height: auto;
    }
    margin-right: 5px;
  }
`;

const ExitIcon = styled(Icon)`
  justify-self: end;
  cursor: pointer;
`;
